<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <div class="mt-10" v-if="video.length === 0">
      <v-alert type="error">
        Belum Ada <strong>video</strong> Untuk Saat Ini
      </v-alert>
    </div>

    <div class="mt-10 pb-10" v-else>
      <v-row>
        <v-col cols="12" md="4" v-for="item in video" :key="item.video_id">
          <v-card class="pa-3">
            <div>
              <iframe :src="item.video_url" width="100%" height="300"> </iframe>
            </div>
            <div class="mt-2">
              <h3>{{ item.video_nama }}</h3>
            </div>
            <div class="mt-2">
              <span class="subtitle-2 grey--text">
                {{ item.video_ket }}
              </span>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Data",
  data: () => ({
    video: [],
    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Galeri",
        disabled: true,
        href: "",
      },
      {
        text: "Video",
        disabled: false,
        href: "/galeri/video",
      },
    ],
  }),
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "video")
        .then((res) => {
          this.jumlah = res.data.count;
          this.video = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
